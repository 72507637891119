import React from 'react';
import './App.css';
import topCorner from './images/sunflower-top-corner.png'
import bottomCorner from './images/sunflower-bottom-corner.png'

function App() {
  return (
    <div className="App">
      <img width="400" height="400" className="topDecorativeImage" src={bottomCorner} alt="sunflower"/>
      <header className="App-header">
          <h1>Ellie May Goods</h1>
          <p>Coming in Fall 2020!</p>
      </header>
      <div className="hero"></div>
      <img width="400" height="400" className="bottomDecorativeImage" src={bottomCorner} alt="sunflower"/>
    </div>
  );
}

export default App;
